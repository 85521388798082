import React from "react";
import group from "../images/ecell/about-bg.jpeg";
import Layout from "../components/layout";
import { Link } from "gatsby";

const AboutPage = () => (
  <Layout>
    <section id="about" className="section general-page">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8  text-center">
            <div className="page-heading">
              <h2 className="display-4">About Us</h2>

              <ul className="list-unstyled list-inline">
                <li className="list-inline-item"><Link to={"/"}>Home </Link></li>
                <li className="list-inline-item"><a>/</a></li>
                <li className="list-inline-item"><a href="#" className="text-muted">About Us</a></li>
              </ul>

            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="about-page" className="section about-page">

      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-heading">
              <h6 className="subtitle text-primary">About E-CELL</h6>
              <h2 className="display-4">Promoting Entrepreneurship</h2>
            </div>
          </div>
        </div>

        <div className="row">
        </div>
        <div className="row">
          <div className="col-lg-12">
            <p>Entrepreneurship cell, Chitkara University Himachal Pradesh aims at fostering the problem-solving
              abilities and spirit of start-up ecosystem by providing the students with a platform which gives them
              number of innovative opportunities to develop the Entrepreneur in them. Our entrepreneurship cell provides
              professional mentorships and other employer opportunities for building an ecosystem for start-ups.</p>
            <h4>Why associate with us?</h4>
            <p>Entrepreneurship Cell, here believes in promoting entrepreneurial mindset in students with hidden
              potential. Students get a sense of professionalism and learn the importance of social development. The key
              in managing resources is inculcated in the students. An ecosystem with mentors, budding entrepreneurs,
              angel investors, industry experts and venture capitalists is being developed with a goal of building an
              enterprising India.</p>

          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default AboutPage;
